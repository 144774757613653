
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































::v-deep {
  .loader-info {
    font-size: 1.6rem;
    text-align: center;
  }

  .infinite-loading-container {
    margin-bottom: 10rem;
  }

  .retry-button {
    text-decoration: underline;
  }
}
